import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/app'
import 'firebase/auth'
import '@/assets/css/bootstrap.min.css'
import '@/assets/css/gl-styles.css'
import '@/assets/css/toggleTab.css'
import '@/assets/css/fns-css.css'
import '@/assets/css/font-awesome.min.css'
import '@/assets/css/util.css'
import '@/assets/css/main.css'
import VModal from 'vue-js-modal'
import VueConfirmDialog from 'vue-confirm-dialog'
Vue.use(VModal)
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
const configFirebase = {
  apiKey: 'AIzaSyDtwmrCXoIj0MvWUD-PbTrFF8A-Phr6vzo',
  authDomain: 'minigame-pro.firebaseapp.com',
  databaseURL: 'https://minigame-pro-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'minigame-pro',
  storageBucket: 'minigame-pro.appspot.com',
  messagingSenderId: '274982486926',
  appId: '1:274982486926:web:008dc777d66a78ed8a9adf'
}
firebase.initializeApp(configFirebase)
Vue.config.productionTip = false
Vue.prototype.$projectId = 12
Vue.prototype.$music = null
let app: any
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
